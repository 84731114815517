import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>RideAmigos Mobile Trip Planning</title>
            </Helmet>

            <Header/>

            <section class="bg-rideamigos">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1>RideAmigos Mobile Trip Planning</h1>
                            <small>
                                design / prototyping / user testing
                            </small>

                            <p class="lead">
                                Design, prototype and testing of new mobile trip planning
                                feature
                            </p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/card-thumb-mobile-trip-planning.png"
                                alt="Commute tracker mobile screen"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h4>Problem</h4>
                            <p class="">
                                RideAmigos Commute Tracker is a location-aware app that
                                automates the process of logging a commuter’s trips and provides
                                employer-funded incentives to encourage more sustainable
                                choices. For several years, users could discover and plan
                                alternative commutes on the website. To increase behavior
                                change, commuters needed to be able to do this on the mobile app
                                as well. For business reasons the feature needed to be
                                implemented in just a few months. All of this presented several
                                design challenges:
                            </p>
                            <ul class="">
                                <li>
                                    {" "}
                                    Design a location-aware mobile app that was familiar to users
                                    of the website
                                </li>
                                <li>
                                    {" "}
                                    Design and build features to work on a brand-new hybrid
                                    native/webview architecture
                                </li>
                                <li>Go from design to production with minimal user research</li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <h4>The Solution</h4>
                            <p class="">
                                We began by working a rapid UX design, prototype and testing
                                process into the agile release cycle. At the same time, we began
                                working on the technical challenges of the new hybrid app.
                                Eventually, these paths converged and we began rolling out
                                periodic betas for testing.
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <h4>My Role</h4>
                            <p class="">
                                My primary role in this project was to plan and execute the
                                design, prototyping and user testing. A secondary role was to
                                help out with front-end development for the Angular portion of
                                the app.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-2">
                            <h2>Research</h2>

                            <p class="">
                                Due to the short timeline and very limited resources, the
                                initial research included a rapid design, prototype and testing
                                process. We conducted several early cognitive interviews with an
                                XD interactive prototype in order to catch any major UX blockers
                                early.
                            </p>
                        </div>

                        <div class="col-sm-8 col-lg-4 order-2 order-lg-1">
                            <a href="#research">
                                <figure class="figure">

                                    <StaticImage
                                        src="../images/mobile-trip-planning-research.png"
                                        alt="Person using mobile trip plannning on phone"
                                        placeholder="blurred"
                                        className="img-fluid"
                                    />

                                    <figcaption class="figure-caption text-center">
                                        Early research relied on cognitive walkthroughs of clickable
                                        prototypes
                                    </figcaption>
                                </figure>
                            </a>
                        </div>

                        <div class="col-sm-4 col-lg-2 order-2 order-lg-1">
                            <a href="#research-animated">
                                <figure class="figure">

                                    <img
                                        src="/images/mobile-trip-planning-research-animated.gif"
                                        class="figure-img img-fluid shadow"
                                        alt="Animated screenshot of commute tracker app"
                                    ></img>
                                    <figcaption class="figure-caption text-center">
                                        The first clickable prototype focused on the mobile flow
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h2>Insights</h2>

                            <p class="">
                                Over the course of testing, we found and addressed a number of
                                small UX issues. For example, we found that many users were
                                confused by the language to toggle between “Search for Rider”
                                and “Search for Driver." By switching from words to icons, the
                                usability was (surprisingly) improved.
                            </p>

                            <p class="">
                                In early testing, we also found that users were not discovering
                                and chooseing alternative commute modes. To remedy this, we
                                added more available options to the first screen of the
                                interface.{" "}
                            </p>
                        </div>

                        <div class="col-lg-6 order-2 order-lg-1">
                            <a href="#insights">
                                <figure class="figure">

                                    <StaticImage
                                        src="../images/mobile-trip-planning-insights.png"
                                        alt="Commute tracker screens with arrows between them"
                                        placeholder="blurred"
                                        className="figure-img img-fluid"
                                    />

                                    <figcaption class="figure-caption text-center">
                                        Testing revealed several UI and user flow improvements
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-2">
                            <h2>Prototypes</h2>
                            <p class="">
                                Since we are an Agile organization, the early clickable
                                prototypes were quickly replaced by beta versions of the app
                                which we used in later rounds of cognitive walkthroughs.
                            </p>
                        </div>
                        <div class="col-lg-6 order-2 order-lg-1">
                            <a href="#prototypes">
                                <figure class="figure">

                                    <StaticImage
                                        src="../images/mobile-trip-planning-prototypes.png"
                                        alt="Commute tracker screens with arrows between them"
                                        placeholder="blurred"
                                        className="figure-img img-fluid"
                                    />

                                    <figcaption class="figure-caption text-center">
                                        Testing began with Adobe XD protypes and ended with the live
                                        app
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-1 order-lg-1">
                            <h2>Testing and Iteration</h2>

                            <p class="">
                                Since launch in late Summer 2019, we have added in-app analytics
                                to the testing and iteration process. A first round of in-depth
                                mobile user interviews is currently in the planning phase.
                            </p>
                        </div>

                        <div class="col-lg-6 order-2 order-lg-2">
                            <a href="#testing">
                                <figure class="figure">

                                    <StaticImage
                                        src="../images/mobile-trip-planning-testing.png"
                                        alt="Person using Commute Tracker on phone"
                                        placeholder="blurred"
                                        className="figure-img img-fluid"
                                    />

                                    <figcaption class="figure-caption text-center">
                                        A cognitive walkthrough with the prototype running on a
                                        phone
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-2">
                            <h2>Conclusions</h2>

                            <p class="">
                                There were a number of constraints for this project, including
                                time pressure, limited resources, and the need to significantly
                                change an existing and familiar application. We were able to
                                limit the risks through early prototyping, user testing, and by
                                rolling out the application using a phased approach.
                            </p>
                            <p class="">
                                Since launch the application usage has increased along with its
                                ratings in both the Android and iOS app stores. Several new
                                customers have cited the Mobile Trip Planning feature as a
                                significant part of their decision to use the service, and use
                                of the Mobile Trip Planning feature has increased. It is
                                important to point out that this project was a hard-won success
                                by an excellent team of developers, testers and support staff.
                            </p>
                        </div>

                        <div class="col-lg-6 order-2 order-lg-1">
                            <a href="#conclusions">
                                <figure class="figure">

                                    <StaticImage
                                        src="../images/mobile-trip-planning-conclusions.png"
                                        alt="Image of Google Developer console"
                                        placeholder="blurred"
                                        className="figure-img img-fluid"
                                    />

                                    <figcaption class="figure-caption text-center">
                                        The Google Play console for Commute Tracker
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div class="lightbox-target" id="research">
                <StaticImage
                    src="../images/mobile-trip-planning-research.png"
                    placeholder="blurred"
                />

                <a class="lightbox-close" href="#"></a>
            </div>

            <div class="lightbox-target" id="research-animated">
                <img src="/images/mobile-trip-planning-research-animated.gif"/>
                <a class="lightbox-close" href="#"></a>
            </div>

            <div class="lightbox-target" id="insights">
                <StaticImage
                    src="../images/mobile-trip-planning-insights.png"
                    placeholder="blurred"
                />

                <a class="lightbox-close" href="#"></a>
            </div>

            <div class="lightbox-target" id="prototypes">
                <StaticImage
                    src="../images/mobile-trip-planning-prototypes.png"
                    placeholder="blurred"
                />

                <a class="lightbox-close" href="#"></a>
            </div>

            <div class="lightbox-target" id="testing">
                <StaticImage
                    src="../images/mobile-trip-planning-testing.png"
                    placeholder="blurred"
                />

                <a class="lightbox-close" href="#"></a>
            </div>

            <div class="lightbox-target" id="conclusions">
                <StaticImage
                    src="../images/mobile-trip-planning-conclusions.png"
                    placeholder="blurred"
                />

                <a class="lightbox-close" href="#"></a>
            </div>
        </main>
    );
}

export default Index;
